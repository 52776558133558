import React from 'react';
import Layout from '../components/Layout';
import ESBuildersIMG from '../images/esbuilders.png'
import TheMrsGoldIMG from '../images/themrsgold.png'
import PerlaSuiteIMG from '../images/perlasuite.png'
import HourglassFigureIMG from '../images/hourglassfigure.png'

const rowPadding = {
  padding: "150px 0"
}

export default function OurWork() {
  return (
    <Layout>
      <section className='w-100 our-work-bg' style={{marginTop: '74px'}}>
        <div className='container'>
          <div className='row' style={rowPadding}>
            <div className='col-md-6 mx-auto text-center'>
              <h1 className='display-5'>Our Works</h1>
              <div className='line-divider my-3 mx-auto' style={{ borderColor: "#fff" }}></div>
              <p className='fs-5'>
                Every project is unique with their own specific needs and desired outcomes. See how we worked with our previous clients to plan and execute a project that exceeded their expectations. 
              </p>
            </div>
          </div>
        </div>
      </section>

      <section className='w-100 bg-white text-dark'>
        <div className='container'>
          <div className='row g-5 justify-content-center mt-0' style={rowPadding}>

            <div className='col-12 col-md-7 text-center mt-0'>
                <h2 className='display-5 mb-3'>Our Works</h2>
                <p className='fs-18'>
                    GoogolWeb contains a variety of website designs for different business types, 
                    that you can choose from and customize.
                </p>
            </div>

            <div className='col-md-6'>
                <div className="">
                    {/* <StaticImage src='../images/esbuilders.png' className="img-fluid " alt="ESBuilders Site"></StaticImage> */}
                    <img src={HourglassFigureIMG} className="img-fluid " alt="ESBuilders Site"></img>
                    <h5 className='p-4 text-center'>Hourglass Figure</h5>
                </div>
            </div>

            <div className='col-md-6'>
                <div className="">
                    {/* <StaticImage src='../images/hourglassfigure.png' className="img-fluid " alt="HourglassFigure Site"></StaticImage> */}
                    <img src={PerlaSuiteIMG} className="img-fluid " alt="HourglassFigure Site"></img>
                    <h5 className='p-4 text-center'>Perla Suite</h5>
                </div>
            </div>

            <div className='col-md-6'>
                <div className="">
                    {/* <StaticImage src='../images/perlasuite.png' className="img-fluid " alt="PerlaSuite Site"></StaticImage> */}
                    <img src={TheMrsGoldIMG} className="img-fluid " alt="TheMrsGold Site"></img>
                    <h5 className='p-4 text-center'>The Mrs Gold</h5>
                </div>
            </div>

            <div className='col-md-6'>
                <div className="">
                    {/* <StaticImage src='../images/themrsgold.png' className="img-fluid " alt="TheMrsGold Site"></StaticImage> */}
                    <img src={ESBuildersIMG} className="img-fluid " alt="TheMrsGold Site"></img>
                    <h5 className='p-4 text-center'>ES Builders</h5>
                </div>
            </div>

            {/* <div className='d-flex justify-content-center'>
                <a className="btn btn-orange default-padding  text-capitalize" href="/our-work" role="button">See more works</a>
            </div> */}
          </div>
        </div>
      </section>
    </Layout>
  );
}
